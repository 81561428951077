// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
//import addIndicators from 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import { MagnificPopup } from 'magnific-popup';

// t: current time, b: begInnIng value, c: change In value, d: duration
$.easing.jswing = $.easing.swing;

$.extend($.easing,
{
    def: 'easeInOutExpo',
    swing: function (x, t, b, c, d) {
        //alert($.easing.default);
        return $.easing[$.easing.def](x, t, b, c, d);
    },
    easeInOutExpo: function (x, t, b, c, d) {
        if (t==0) return b;
        if (t==d) return b+c;
        if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
        return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
    }
});

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
       // Edge (IE 12+) => return version number
       return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

$(() => {
	if( detectIE() && detectIE() < 12 ){
		$('body').addClass('fix-ie');
	}
});

$(() => {
	var prop = detectIE() ? 'srcFallback' : 'src';
	$('[data-js-logo-animado]').each((index, element) => {
		var srcAttr = $(element)[0].dataset[prop];
		$(element).attr('src', srcAttr);
	});
})

$(() => {
	var controller = new ScrollMagic.Controller();
	var $sections = $('[data-js-section]');
	var arr = [];

	var calcDuration = function(el){
		return $(el).innerHeight() - 50
	}

	for(var i=0 ; i<$sections.length ; i++){
		var element = $sections[i];
		var pin = $(element).find('.sticky-element');
		var scene = new ScrollMagic.Scene({
			triggerHook: 1,
			triggerElement: element,
			duration: calcDuration(element),
			offset: 50
		});
		if(pin.length){
			scene.setPin( pin[0] )
		}
		scene.addTo(controller);
		scene.on('enter', ()=>{
			$(window).resize();
		})
		arr.push({
			element: element,
			pin: pin,
			scene: scene
		})
	}

	$(window).resize(function(){
		for(var i=0 ; i<arr.length ; i++){
			arr[i].scene.duration(calcDuration(arr[i].element))
		}
	}).resize();
});

$(() => {
	var controller = new ScrollMagic.Controller();
	var elements = $('[data-js-scroll-in]');
	$(elements).each((index, element) => {
		var scene = new ScrollMagic.Scene({
			triggerHook: 1,
			triggerElement: element,
			offset: element.dataset['jsScrollIn'] || 120
		})
		//.addIndicators()
		.setClassToggle(element, 'scrollIn')
		.addTo(controller);
	})
})

$(() => {
	$('[data-js-scroll-to-anchor]').click(function(){
		var body = $("html, body");
		var target = $(this).data('jsScrollToAnchor');
		var targetScroll = $(target).offset().top
		body.stop().animate({scrollTop: targetScroll}, 1000, 'easeInOutExpo');
	});
});

// $(() => {
// 	$('[data-js-reload-logo]').click(event => {
// 		var src = $(this).attr('src');
// 		console.log(src);
// 	})
// })

$(() => {
	$('.popup-youtube').magnificPopup({
		type: 'iframe',
		iframe: {
			patterns: {
				youtube: {
					index: 'youtube.com', 
					id: 'v=', 
					src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
				 }
			}
		}
	});
})

$(() => {
	var arr = [];
	var updateWindow = () => {
		$(window).resize();
	}
	var scrollToFitSection = (index) => {
		var sectionOffsetTop = $(arr[index].element).offset().top - 20;
		console.log($(arr[index].element).offset());
		var body = $("html, body");
		body.stop().animate({scrollTop: sectionOffsetTop}, 300, 'swing');
	}
	var closePanels = () => {
		for(var i=0 ; i<arr.length ; i++){
			closePanel(i);
		}
	}
	var closePanel = (index) => {
		$(arr[index].element).removeClass('active');
	}
	var openPanel = (index) => {
		$(arr[index].element).addClass('active');
		scrollToFitSection(index);
	}
	var togglePanel = (index) => {
		if( $(arr[index].element).hasClass('active') ){
			closePanel(index);
		}else{
			closePanels();
			openPanel(index);
		}
	}
	var clickPanelHandler = index => {
		togglePanel(index);
	}
	$('[data-js-sponsorship-table-section]').each((index, element) => {
		var obj = {
			element: $(element),
			head: $(element).find('.sponsorship-table--head'),
			content: $(element).find('.sponsorship-table--content'),
			contentWrapper: $(element).find('.sponsorship-table--content .wrapper'),
			closeBtn: $(element).find('.sponsorship-table--content .close')
		}
		$(obj.head).on('click', function(evt){
			clickPanelHandler(index);
			updateWindow()
		});
		$(obj.closeBtn).on('click', function(evt){
			closePanel(index);
			updateWindow();
		});
		arr.push(obj);
	})
})

$(() => {
	$('body').addClass('initialized');
})